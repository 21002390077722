<template><Logo/></template>

<script>
export default {
  name: 'LogoSketchy',
  components: {
    Logo: () => import('./inline.sketchy_logo.svg'),
  },
}
</script>
